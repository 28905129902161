@use "../../sass/variables" as *;

.backdrop {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: rgba($slate-800, 0.5);
}
.modal {
  width: 600px;
  max-width: 100%;
  position: fixed;
  z-index: 30;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  //box-shadow: 0 6px 8px rgba($slate-800, 0.9);
  // animation: slide-down 300ms ease-out forwards;
  &-titlebar {
    padding: $space-sm $space calc(#{$space} - 5px);
    display: flex;
    // background-color: orange;
    border-bottom: 1px solid $slate-200;
    position: relative;
    .title {
      margin: 0;
    }
  }
  &-content {
    padding: $space-l;
    overflow: scroll;
  }
}
@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
