@use "../../sass/variables" as *;

.main-content.logged-in {
	margin-top: $topbar-height;
}

.sidebar--open + .main-content {
	margin-left: $sidebar-width-open;
}

.sidebar--closed + .main-content {
	margin-left: $sidebar-width-closed;
}
