@use "./variables" as *;
@use "./utilities" as *;

:root {
	--toastify-font-family: "ubuntu", sans-serif;
	--toastify-color-success: #{$green-500};
}

.Toastify {
	&__toast {
		border-radius: 0;
		&-body {
			color: $text-black;
			line-height: $line-height;
			font-size: $font-sm;
		}
	}
	&__close-button {
		opacity: 1;
		align-self: center;
	}
	&__progress-bar {
		height: 2px;
	}
}

.slide-top {
	-webkit-animation: slide-top 0.2s ease-in-out reverse both;
	animation: slide-top 0.2s ease-in-out reverse both;
}
.slide-top-hide {
	-webkit-animation: slide-top 0.2s ease-in-out both;
	animation: slide-top 0.2s ease-in-out both;
}

@-webkit-keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
@keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
@-webkit-keyframes slide-top-hide {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
@keyframes slide-top-hide {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}

.badge-value {
	&__draft {
		background-color: $slate-400;
	}
	&__submitted {
		background-color: $gold;
	}
	&__reviewed {
		background-color: $green-500;
	}
	&__class-study,
	&__self-study {
		background-color: transparent;
		color: $slate-400;
		border: 1px solid $slate-400;
	}
	&__insufficient {
		background-color: $red-600;
	}
	&__sufficient {
		background-color: $sky-400;
	}
	&__well-done {
		background-color: $green-500;
	}
}

.message {
	padding: 10px;
	border-left: 5px solid;
	border-radius: 3px;
	font-size: $font-xs;
	display: flex;
	gap: 10px;
	&:empty {
		display: none;
	}
	i {
		height: 20px;
		width: 20px;
		align-self: center;
		font-size: $font-lg;
		line-height: 1;
	}
	&__error {
		@extend .message;
		border-color: $red-600;
		background: lighten($red-600, 42);
		color: $red-600;
	}
	&__note {
		@extend .message;
		border-color: $sky-600;
		background: lighten($sky-400, 42);
		color: $sky-700;
	}
}
