@use "../../sass/variables" as *;

.badge {
  padding: 2px 8px;
  margin-top: 2px;
  border-radius: 5px;
  background-color: $slate-600;
  color: $white;
  font-size: $font-xs;
  font-weight: 500;
  white-space: nowrap;
  position: relative;
  // bottom: -1px;
  &:not(:last-of-type) {
    margin-right: 5px;
  }
}

table .badge {
  bottom: 0;
}
