@use "../../../sass/variables" as *;

.textarea-container {
  position: relative;
  border: 1px solid $white;
  border-radius: $border-radius;
  background-color: $white;
  //&:not([readonly]) {
  border: 1px solid $slate-300;
  //}
  &:focus-within label,
  label.filled {
    padding: 0 4px;
    transform: translate(-4px, calc(-#{$space} - 1rem + 6px));
    font-size: 85%;
    background-color: inherit;
  }
  label {
    position: absolute;
    top: $space;
    left: $space-sm;
    transition: $transition;
    border-radius: $border-radius;
    color: $slate-400;
    line-height: 1rem;
    pointer-events: none;
  }
  textarea {
    height: 100%;
    width: 100%;
    padding: 0.75rem;
    border-radius: $border-radius;
    background-color: transparent;
    resize: none;
    font-size: inherit;
  }
}
