@use "../../sass/variables" as *;

.patient-widget {
	height: 7rem;
	background-color: $slate-100;
	.grid {
		display: grid;
		grid-auto-flow: column;
		grid-template-rows: repeat(3, 2fr);
		grid-template-columns: repeat(3, 2fr);
		& > * {
			label {
				width: 8rem;
				font-size: $font-xs;
				font-weight: 700;
			}
		}
		.history {
			flex-wrap: wrap;
			grid-row: 2 / span 2;
			overflow-y: scroll;
		}
	}
	.table-row {
		max-width: 33%;
	}
}
