@use "../../sass/variables" as *;

.search-wrapper {
  // TODO: calculate the min height properly based on the input height
  min-height: 49px;
}

.table-wrapper {
  max-height: 100%;
  overflow: auto;
}

.table,
.inline-table {
  border-collapse: collapse;
  width: 100%;
  tr {
    border-bottom: 1px solid $slate-300;
    text-align: left;
  }
  th {
    font-size: $font-xs;
    font-weight: 500;

    i {
      font-size: inherit;
      margin-left: 4px;
      color: $slate-400;
    }
    &.sort-asc i,
    &.sort-desc i {
      color: inherit;
    }
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: $slate-100;
    color: $black;
  }
  tbody tr {
    /*&:nth-child(even) {
      background-color: $slate-100;
    }*/
    &:hover {
      background-color: $slate-100;
    }
    &.selected {
      background-color: $green-200;
    }
    &.selectable {
      cursor: pointer;
    }
  }
  tbody {
    .actions-wrapper {
      white-space: nowrap;
      .btn.action {
        width: 32px;
        height: 32px;
        min-width: auto;
        padding: 4px;
        display: inline-block;
      }
    }
  }
  td.datetime {
    font-size: 0.85rem;
    line-height: 1.5;
    white-space: pre-line;
  }
}
