@use "../../sass/variables" as *;

.select-patient-modal {
	min-width: 1200px;
	.modal-content {
		//background-color: $slate-100;
	}
	.aside {
		.table {
			.table-row > *:first-child {
				width: 50%;
				white-space: nowrap;
			}
		}
	}
}

/*.aside {
	margin-top: -$space;
	margin-right: -$space;
	height: calc(100% + 2 * $space);
}
*/
