@use "../../sass/variables" as *;

.main-content.login {
	padding: 10vh $space $space;
}

.app-title {
	font-weight: 700;
	font-size: 6rem;
	line-height: 1;
	letter-spacing: -0.05em;
	color: $color-primary;
}

.app-subtitle {
	display: none;
	margin-top: 6px;
	font-size: 17px;
	line-height: 1;
	color: $sky-400;
}
