@use "../../../sass/variables" as *;

.input-container {
  position: relative;
  border: 1px solid $slate-300;
  border-radius: $border-radius;
  background-color: $white;
  &:focus-within label,
  label.filled {
    padding: 0 4px;
    transform: translate(-4px, calc(-#{$space} - 1rem + 9px));
    font-size: 85%;
    background-color: inherit;
    & + input {
      &:focus::placeholder {
        color: $slate-400;
      }
    }
  }
  label {
    position: absolute;
    top: calc($space - 3px);
    left: $space-sm;
    transition: $transition;
    border-radius: 4px;
    color: $slate-400;
    line-height: 1rem;
    pointer-events: none;
  }
  input {
    width: 100%;
    height: 100%;
    padding: $space-sm $space-sm calc($space-sm - 3px);
    border-radius: $border-radius;
    font-size: 1rem;
    line-height: 1rem;
    &[readonly] {
      background-color: $slate-100;
    }
    &::placeholder {
      color: transparent;
    }
    &.invalid {
      border-left: 5px solid $red-600;
      padding-left: calc($space-sm - 5px);
      color: $red-600;
    }
  }
  &.password {
    display: flex;
    input {
      width: calc(100% - 1em - 16px);
    }
    .password-visibility-toggler {
      align-self: center;
      padding: 0 8px 0 8px;
      background-color: transparent;
      cursor: pointer;
      color: $slate-400;
    }
  }
}

.input-message {
  position: absolute;
  padding: 0 $space-sm;
  font-size: 0.95rem;
}
