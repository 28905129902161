@use "../../../sass/variables" as *;

$height: 24px;
$width: 42px;
$padding: 3px;
$slider-size: calc($height - 2 * $padding); // 18px

.toggle-container {
  label.switch {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: $width;
    height: $height;
    cursor: pointer;
    &:focus-within {
      .slider {
        transition: none;
        outline: 2px solid $color-primary;
        outline-offset: 1px;
      }
    }
    input {
      height: 0;
      width: 0;
      opacity: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $slate-300;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        height: $slider-size;
        width: $slider-size;
        left: $padding;
        bottom: $padding;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input:checked + .slider {
      background-color: $color-primary;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(calc($width - $slider-size - 2 * $padding));
      -ms-transform: translateX(calc($width - $slider-size - 2 * $padding));
      transform: translateX(calc($width - $slider-size - 2 * $padding));
    }

    .slider.round {
      border-radius: $height;
    }
    .slider.round:before {
      border-radius: 50%;
    }
    .label-text {
      display: inline-block;
      margin-left: calc($width + 0.5rem);
    }
  }
}
