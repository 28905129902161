@use "./variables" as *;

.main-content {
	height: 100vh;
	overflow: auto;
	&:not(.login) {
		max-height: calc(100vh - $topbar-height);
		height: calc(100vh - $topbar-height);
	}
	.header {
		h1 {
			margin-bottom: 1rem;
			.badge {
				position: relative;
				top: -2px;
			}
		}
	}
	.description:not(:empty) {
		// 3 rows is aprox. 3 times the line height;
		min-height: calc(3 * $line-height * 1em);
		white-space: pre-line;
		font-size: 0.95rem;
	}
}

.view {
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	.content {
		height: 75%;
		max-height: 100%;
		margin-top: 1rem;
		flex: 1;
		display: flex;
		flex-direction: column;
		& > h2 {
			margin: 0;
		}
	}
	.actions {
		margin-top: 1rem;
	}
}

.modal {
	.modal-content {
		h2 {
			margin: 0;
		}
	}
}

.aside {
	width: 340px;
	min-width: 340px;
	padding: $space-l;
	margin: -$space-l;
	margin-left: $space-l;
	overflow: scroll;
	background-color: $slate-100;
	.table .table-row > *:nth-child(2n + 1) {
		padding-left: 0;
		min-width: 50%;
	}
	.table .table-row > *:nth-child(2n) {
		padding-left: 0;
		padding-right: 0;
	}
}
