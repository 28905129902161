@use "../../sass/variables" as *;

.topnav {
  width: 100vw;
  height: $topbar-height;
  padding: 0 8px;
  line-height: 1;
  background-color: $slate-300;
  gap: $space-sm;
  position: fixed;
  top: 0;
  z-index: 20;
}

.profile-link {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-decoration: none;
  i {
    color: $slate-800;
    font-size: 24px;
  }
}
