@use "./variables" as *;

/*animation*/

/* table */
.table {
  display: table;
}

.inline-table {
  display: inline-table;
}

.table-row {
  display: table-row;
  &:not(:last-of-type) {
    // border-bottom: 1px solid $slate-300;
  }
  & > * {
    display: table-cell;
    padding: $space;
  }
}

.min-cell-width {
  width: 1%;
  white-space: nowrap;
}

.aside {
  .table-row > * {
    padding: 0.45rem 1rem;
  }
}

/*rotate*/
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-360 {
  transform: rotate(360deg);
}
/*scale*/
.scale-0 {
  transform: scale(0);
}

/* flex system */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: 1 1 auto;
}
.flex-wrap {
  flex-wrap: wrap;
}
.inline-flex {
  display: inline-flex;
}
.col {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.self-start {
  align-self: flex-start;
}
.self-center {
  align-self: center;
}
.center {
  align-items: center;
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.align-end {
  align-items: flex-end;
}

.justify-cntr {
  justify-content: center;
}
.self-end {
  align-self: flex-end;
}
.wrap {
  flex-wrap: wrap;
}
.items-stretch {
  align-items: stretch;
}
.gap-1 {
  gap: $space;
}

.gap-2 {
  gap: $space-md;
}

.col-gap-1 {
  column-gap: $space;
}

.col-gap-2 {
  column-gap: $space-md;
}

.row-gap-1 {
  row-gap: $space;
}
.row-gap-2 {
  row-gap: $space-md;
}

/* grids */

.grid {
  display: grid;
}

/*forms*/

/*select*/

/*display*/
.display-none {
  display: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}

/*position*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/**
 * width
 */

.w-40 {
  width: 10rem;
}
.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.min-w-auto {
  min-width: auto;
}

.min-w-50 {
  min-width: 50%;
}

.min-w-96 {
  min-width: 24rem;
}

.w-auto {
  width: auto;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-75 {
  width: 75%;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-80 {
  height: 80%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-90 {
  max-height: 90vh;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-prose {
  max-width: 65ch;
}

/**
 * margin
 */

.m-0 {
  margin: 0;
}

.m-1 {
  margin: $space;
}

.mt-auto {
  margin-top: auto;
}

.mr-1 {
  margin-right: $space;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.my-1 {
  margin-top: $space;
  margin-bottom: $space;
}

.my-2 {
  margin-top: $space-md;
  margin-bottom: $space-md;
}

.my-3 {
  margin-top: calc($space * 2);
  margin-bottom: calc($space * 2);
}

.margin-top-1,
.mt-1 {
  margin-top: $space;
}
.margin-top-2,
.mt-2 {
  margin-top: $space-md;
}

.mt-3 {
  margin-top: calc($space * 2);
}

.mb-05 {
  margin-bottom: calc($space / 2);
}

.mb-1 {
  margin-bottom: $space;
}

.mb-2 {
  margin-bottom: $space-md;
}

.mb-3 {
  margin-bottom: calc($space * 2);
}

.ml-05 {
  margin-left: calc($space / 2);
}

.ml-1 {
  margin-left: $space;
}

.ml-3 {
  margin-left: $space-l;
}

/*padding*/
.p-0 {
  padding: 0 !important;
}
.p-05 {
  padding: calc($space / 2);
}
.p-1 {
  padding: $space;
}
.p-2 {
  padding: $space-md;
}
.p-3 {
  padding: $space-l;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: $space;
  padding-bottom: $space;
}
.px-1 {
  padding-left: $space;
  padding-right: $space;
}
.px-2 {
  padding-left: $space-md;
  padding-right: $space-md;
}
.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: $space;
}
.pr-1 {
  padding-right: $space;
}
.pr-2 {
  padding-right: $space-md;
}

.padding-1 {
  padding: $space;
}

.padding-2 {
  padding: $space-md;
}

/*container*/
.card {
  background-color: $white;
  padding: $space;
  border-radius: $border-radius;
  border: 2px solid rgba($slate-400, 0.2);
}

/* text */
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

/* text-color */
.text-black {
  color: $black;
}

.text-grey {
  color: $slate-400;
}

.text-primary {
  color: $color-primary;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &-20 {
    @extend .truncate;
    max-width: 20ch;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.leading-normal {
  line-height: $line-height;
}

/* font-size */
.font-xxs {
  font-size: $font-xxs;
}

.font-xs {
  font-size: $font-xs;
}

.font-sm {
  font-size: $font-sm;
}

.font-base {
  font-size: $font-base;
}

.font-lg {
  font-size: $font-lg;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: 500;
}

.d-inline-block {
  display: inline-block;
}

.overflow-x-auto {
  overflow-x: auto;
}

.border-none {
  border: none;
}

.border-white {
  border-color: $white;
}

.border-l {
  border-left: 1px solid;
}

.border-b {
  border-bottom: 1px solid;
}

.border-slate-200 {
  border-color: $slate-200;
}

.border-slate-300 {
  border-color: $slate-300;
}

.border-slate-400 {
  border-color: $slate-400;
}

.bg-transparent {
  background-color: transparent;
}

.bg-primary {
  background-color: $color-primary;
}

.bg-slate-200 {
  background-color: $slate-200;
}

.bg-slate-300 {
  background-color: $slate-300;
}

.bg-slate-400 {
  background-color: $slate-400;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.align-middle {
  vertical-align: middle;
}

/*visibility*/
.hidden {
  visibility: hidden !important;
  display: none !important;
}

.bg-white {
  background-color: $white;
}

.list-none {
  list-style: none;
}
