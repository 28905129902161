@use "../../../sass/variables" as *;

.btn {
  min-width: 8rem;
  padding: 8px 12px;
  border: none;
  border-radius: $border-radius;
  outline: none;
  background-color: $slate-300;
  color: $black;
  font-size: 0.95rem;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  &:focus-within {
    outline: 2px solid $color-primary;
    outline-offset: 1px;
  }
  &:hover {
    background-color: darken($slate-300, 4);
  }
  &:not(:last-of-type) {
    margin-right: $space;
  }
  &.disabled {
    pointer-events: none;
    background-color: $slate-300;
    color: $slate-400;
    font-weight: 400;
  }
  &-primary {
    background-color: $color-primary;
    color: $white;
    font-weight: 500;
    &:hover {
      background-color: darken($color-primary, 4);
    }
  }
  &-secondary {
    background-color: $color-secondary;
    color: $white;
    font-weight: 500;
    &:hover {
      background-color: darken($color-secondary, 4);
    }
  }
  &-delete {
    background-color: $red-600;
    color: $white;
    font-weight: 500;
    &:hover {
      background-color: darken($red-600, 4);
    }
  }
  &-close {
    position: absolute;
    right: 6px;
    top: 6px;
    background-color: inherit;
    cursor: pointer;
    &:focus-within {
      border-radius: $border-radius;
      outline: 2px solid $color-primary;
    }
    i {
      display: block;
      font-size: 32px;
    }
  }
}

.btn.inline-action {
  min-width: auto;
  padding: 0 4px;
  margin-left: 6px;
  background: none;
  color: $slate-300;
  &:hover {
    i {
      color: darken($slate-300, 10);
    }
  }
}
