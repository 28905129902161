@use "../../sass/variables" as *;

.dashboard {
	.quicklink {
		width: fit-content;
		color: inherit;
		text-decoration: none;
		border: 1px solid $slate-300;
		border-radius: $border-radius;
		&:hover {
			background-color: darken($slate-200, 0.8);
		}
		&.inactive {
			pointer-events: none;
			background-color: lighten($color-inactive, 40);
			color: $color-inactive;
			i {
				color: $color-inactive;
			}
		}
		i {
			width: 24px;
			height: 24px;
			color: $color-secondary;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			&:before {
				font-size: 24px;
			}
		}
	}
}
