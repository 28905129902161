@use "../../sass/variables" as *;

.prescription-modal {
	width: 1200px;
	.modal-content {
		//background-color: $slate-100;
		overflow: visible;
		.combo-container ul {
			max-height: 15em;
		}
	}
}
